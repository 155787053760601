import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Layout, LayoutContent, LayoutHeader } from '../../Layout';
import { GenericViewProps } from '../../GenericViewProps';

type NotFoundViewProps = RouteComponentProps & WithTranslation & GenericViewProps;

function NotFoundView(props: NotFoundViewProps): JSX.Element {
  return (
    <Layout>
      <LayoutHeader logo={props.logo} />
      <LayoutContent>
        <div className="404-view">
          <div className="pb-12 flex flex-col">
            <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
              <div className="py-16">
                <div className="text-center">
                  <p className="text-sm font-semibold text-[color:var(--link-color)] uppercase tracking-wide">
                    {props.t('404View.Title')}
                  </p>
                  <h1 className="mt-2 text-3xl font-extrabold text-[color:var(--text-color)] tracking-tight sm:text-4xl">
                    {props.t('404View.Header')}
                  </h1>
                  <p className="mt-2 text-base text-[color:var(--text-brightest-color)]">{props.t('404View.Body')}</p>
                  <div className="mt-6">
                    <button
                      className="text-base font-medium text-[color:var(--link-color)] hover:text-[color:var(--focus-color)]"
                      onClick={(): void => props.history.push('/')}
                    >
                      {props.t('404View.Footer')}
                      <span aria-hidden="true"> &rarr;</span>
                    </button>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </LayoutContent>
    </Layout>
  );
}

export const NotFoundViewWithRouter = withTranslation()(withRouter(NotFoundView));
