import { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { AuthorityUnreachableError, IAuthService, isConnectionError } from '../../../lib/index';
import { DelayedRenderer } from '../../components/DelayedRenderer';
import LoadingSpinner from '../../components/LoadingSpinner';
import { GenericViewProps } from '../../GenericViewProps';
import { Layout, LayoutHeader } from '../../Layout';

type LoginPromptViewProps = GenericViewProps & {
  authService: IAuthService;
  redirectAfterLogin: unknown;
};

export function LoginPromptView(props: LoginPromptViewProps): JSX.Element | null {
  const history = useHistory();

  const [restoreUserPending, setRestoreUserPending] = useState(true);
  const [loginError, setLoginError] = useState<Error | null>(null);

  useEffect(() => {
    props.authService.detectLoggedInFlag().then((isLoggedIn) => {
      if (!restoreUserPending) {
        return;
      }
      setRestoreUserPending(false);

      if (isLoggedIn) {
        history.replace('/');
      } else {
        doLogin();
      }
    });
  });

  const doLogin = async (): Promise<void> => {
    setLoginError(null);
    try {
      await props.authService.login(props.redirectAfterLogin);
    } catch (err) {
      if (isConnectionError(err)) {
        setLoginError(new AuthorityUnreachableError(err.code));
      } else {
        setLoginError(err);
      }
    }
  };

  if (loginError) {
    console.error(loginError);
    return <Redirect to={{ pathname: '/signin-oidc', search: `error=${loginError}` }} />;
  }

  return (
    <Layout id="login-prompt-view">
      <LayoutHeader logo={props.logo} />
      <DelayedRenderer timeoutInMs={props.loadingSpinnerActiveOnInitialAccess ? 0 : undefined}>
        <LoadingSpinner style={{ gridArea: 'content' }} />
      </DelayedRenderer>
    </Layout>
  );
}
