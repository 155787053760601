import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import type { IAuthService } from './lib';
import { LogEmitter } from './lib/LogEmitter';

import { TranslatedAppBootstrapper } from './modules/AppBootstrapper';
import { AppSkeleton } from './modules/AppSkeleton';

const { promise: authServicePromise, resolverFunction: authServiceResolver } = getAuthServicePromiseAndResolver();
const logEmitter = new LogEmitter(authServicePromise);

function handleErrorsAndPromiseRejections(
  event: (PromiseRejectionEvent | ErrorEvent) & { additionalInformation?: Record<string, any> }
) {
  // there is a problem with React Error Boundaries leading to error events being thrown twice
  // https://github.com/facebook/react/issues/11499 (marked as "wontfix")
  // we circumvent this by utilizing `errorEvent.error` to store whether we have already seen this error or not

  let additionalInformation = {};
  if (event.additionalInformation) {
    additionalInformation = { ...event.additionalInformation };
  }

  if (event instanceof ErrorEvent) {
    if (event?.error?.alreadyHandledInWindowErrorHandler) {
      return false;
    }

    if (event && event.error) {
      logEmitter.emitErrorLog(event.error, {
        filename: event.filename,
        colno: event.colno,
        lineno: event.lineno,
        errorType: event.type,
        ...additionalInformation,
      });

      event.error.alreadyHandledInWindowErrorHandler = true;
    }
  } else if (event instanceof PromiseRejectionEvent) {
    if (event?.reason?.alreadyHandledInWindowErrorHandler) {
      return;
    }

    logEmitter.emitErrorLog(event.reason, {
      errorType: event.type,
      ...additionalInformation,
    });
  }
}

window.addEventListener('error', handleErrorsAndPromiseRejections);
window.addEventListener('unhandledrejection', handleErrorsAndPromiseRejections);

const extensionsRequire = (name: string): any => {
  if (name === 'react') {
    return React;
  }
  console.warn('Call to require:', name);
};

(window as any).require = extensionsRequire;

const loadingSpinnerElement = document.getElementById('loading-spinner');
const loadingSpinner = {
  element: loadingSpinnerElement,
  isActive: loadingSpinnerElement?.style.display === 'flex',
};

ReactDOM.render(
  <Suspense fallback={<AppSkeleton />}>
    <TranslatedAppBootstrapper resolveAuthServiceForLogEmitter={authServiceResolver} loadingSpinner={loadingSpinner} />
  </Suspense>,
  document.getElementById('root')
);

function getAuthServicePromiseAndResolver(): {
  promise: Promise<IAuthService>;
  resolverFunction: (value: IAuthService | PromiseLike<IAuthService>) => void;
} {
  let resolverFunction: (value: IAuthService | PromiseLike<IAuthService>) => void = () => void 0;
  const promise = new Promise<IAuthService>((resolve) => (resolverFunction = resolve));

  return { promise, resolverFunction };
}
