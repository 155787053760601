import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { Component } from 'react';
import { DataModels, Subscription } from '@atlas-engine/atlas_engine_sdk';
import { EmojiHappyIcon } from '@heroicons/react/outline';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  AnyTaskType,
  EngineService,
  classNames,
  FlowNodeInstanceSortableColumns,
  FlowNodeInstanceSortSettings,
  SortDirection,
  TaskListWithCorrelation,
} from '../../../lib';
import { Layout, LayoutContent, LayoutHeader } from '../../Layout';
import { GenericViewProps } from '../../GenericViewProps';
import { TasksByCorrelationGroup } from '../../components/task-list/TasksByCorrelationGroup';
import { ErrorRenderer } from '../../components/ErrorRenderer';
import { DelayedRenderer } from '../../components/DelayedRenderer';
import LoadingSpinner from '../../components/LoadingSpinner';

type TaskListViewProps = {
  engineService: EngineService;
} & RouteComponentProps &
  WithTranslation &
  GenericViewProps;

type TaskListViewState = {
  isLoading: boolean;
  loadingError: Error | null;
  groupedTasks: Array<TaskListWithCorrelation>;
  showLoadingSpinnerOnInitialRender: boolean;
};

class TaskListView extends Component<TaskListViewProps, TaskListViewState> {
  public state: TaskListViewState = {
    groupedTasks: [],
    isLoading: true,
    loadingError: null,
    showLoadingSpinnerOnInitialRender:
      (this.props.location as any).state?.loadingSpinnerActive === true ||
      this.props.loadingSpinnerActiveOnInitialAccess,
  };

  private taskChangedSubscriptions: Array<Subscription> = [];
  private processChangedSubscriptions: Array<Subscription> = [];

  private readonly applySortBound = this.applySort.bind(this);

  public async componentDidMount(): Promise<void> {
    this.taskChangedSubscriptions = await this.props.engineService.onTaskStatesChanged(this.loadData.bind(this));
    this.processChangedSubscriptions = await this.props.engineService.onProcessInstanceStateChanged(
      this.loadData.bind(this)
    );
    this.loadData();
  }

  componentDidUpdate(
    prevProps: Readonly<TaskListViewProps>,
    prevState: Readonly<TaskListViewState>,
    snapshot?: any
  ): void {
    if (prevProps.location.search !== this.props.location.search) {
      this.loadData();
    }
  }

  public async componentWillUnmount(): Promise<void> {
    await this.props.engineService.removeSubscriptions([
      ...this.taskChangedSubscriptions,
      ...this.processChangedSubscriptions,
    ]);
  }

  public render(): JSX.Element {
    const header = this.props.t('TaskList.Header', { defaultValue: '' });
    const description = this.props.t('TaskList.Description', { defaultValue: '' });

    return (
      <Layout>
        <LayoutHeader activeNav="task-list" logo={this.props.logo} />
        {this.state.isLoading && (
          <DelayedRenderer timeoutInMs={this.state.showLoadingSpinnerOnInitialRender ? 0 : undefined}>
            <LoadingSpinner style={{ gridArea: 'content' }} />
          </DelayedRenderer>
        )}
        <LayoutContent>
          <div className="task-list-view">
            <div className="task-list-view__header">
              <div className="w-full relative">
                {(header || description) && (
                  <div className="inline text-center max-w-lg sm:max-w-xl sm:max-wi">
                    {header && <h2 className="task-list-view__header--text">{header}</h2>}
                    {description && <p className="task-list-view__header--description">{description}</p>}
                  </div>
                )}
              </div>
            </div>
            <div className="task-list-view__task-list">
              {this.state.loadingError && <ErrorRenderer error={this.state.loadingError} />}
              {!this.state.isLoading && !this.state.loadingError && this.renderContent()}
            </div>
          </div>
        </LayoutContent>
      </Layout>
    );
  }

  private renderContent(): JSX.Element {
    if (this.state.groupedTasks.length === 0) {
      return <EmptyState t={this.props.t} />;
    }

    const sortSettings = this.getCurrentSortSettings();

    return (
      <div className="overflow-hidden mt-8 px-px pt-px pb-8">
        <div className="overflow-auto shadow shadow-[color:var(--shadow-color)] ring-1 ring-[color:rgb(var(--outline-color)/var(--tw-ring-opacity))] ring-opacity-5 md:rounded-lg">
          <table className="task-list-view__table">
            <thead>
              <tr>
                <th scope="col">
                  <TableSortIndicator
                    label={this.props.t('TaskList.TableHeader.Date')}
                    column={FlowNodeInstanceSortableColumns.createdAt}
                    sortBy={sortSettings.sortBy}
                    sortDir={sortSettings.sortDir}
                    applySort={this.applySortBound}
                  />
                </th>
                <th scope="col">
                  <TableSortIndicator
                    label={this.props.t('TaskList.TableHeader.Process')}
                    column={FlowNodeInstanceSortableColumns.processModelName}
                    sortBy={sortSettings.sortBy}
                    sortDir={sortSettings.sortDir}
                    applySort={this.applySortBound}
                  />
                </th>
                <th scope="col">
                  <TableSortIndicator
                    label={this.props.t('TaskList.TableHeader.Task')}
                    column={FlowNodeInstanceSortableColumns.flowNodeName}
                    sortBy={sortSettings.sortBy}
                    sortDir={sortSettings.sortDir}
                    applySort={this.applySortBound}
                  />
                </th>
                <th scope="col">
                  <span className="sr-only">{this.props.t('TaskList.Continue')}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.groupedTasks.map((taskByCorrelation) => (
                <TasksByCorrelationGroup key={taskByCorrelation.correlation.correlationId} {...taskByCorrelation} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  private async loadData(): Promise<void> {
    try {
      const groupedTasks = await this.loadTasks();
      this.setState({ loadingError: null, groupedTasks: groupedTasks, isLoading: false });
    } catch (error) {
      this.setState({ loadingError: error, isLoading: false });
    }
  }

  private async loadTasks(): Promise<TaskListWithCorrelation[]> {
    const sortSettings = this.getCurrentSortSettings();
    const sortSettingsToUse =
      sortSettings.sortBy === FlowNodeInstanceSortableColumns.processModelName
        ? undefined
        : (sortSettings as DataModels.FlowNodeInstances.FlowNodeInstanceSortSettings);
    const tasks = await this.props.engineService.getTasks(sortSettingsToUse);
    const correlationIds = tasks.map((task) => task.correlationId);
    const uniqueCorrelationIds = [...new Set(correlationIds)];

    const instances = await this.props.engineService.getProcessInstancesBy({
      correlationId: uniqueCorrelationIds,
      state: DataModels.ProcessInstances.ProcessInstanceState.running,
    });

    const groupedTasks = await this.groupTasksByCorrelation(tasks, instances);

    const sortedTasks =
      sortSettings.sortBy === FlowNodeInstanceSortableColumns.processModelName
        ? groupedTasks.sort((a, b) => {
            const nameA = a.correlationName ?? this.props.t('TaskList.ProcessNameUnknown') ?? '';
            const nameB = b.correlationName ?? this.props.t('TaskList.ProcessNameUnknown') ?? '';

            return sortSettings.sortDir === SortDirection.ASC ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
          })
        : groupedTasks;

    return sortedTasks;
  }

  private async groupTasksByCorrelation(
    tasks: Array<AnyTaskType>,
    instancesWithCorrelationsOfTasks: Array<DataModels.ProcessInstances.ProcessInstance>
  ): Promise<Array<TaskListWithCorrelation>> {
    if (tasks.length === 0 || instancesWithCorrelationsOfTasks.length === 0) {
      return [];
    }

    const taskListWithCorrelation: { [correlationId: string]: TaskListWithCorrelation } = {};

    for (const task of tasks) {
      const instancesForTask = instancesWithCorrelationsOfTasks.filter(
        (instance) => instance.processInstanceId === task.processInstanceId
      );

      const noCorrelation = instancesForTask.every((instance) => instance.correlation == null);
      if (noCorrelation) {
        continue;
      }

      const rootProcessInstance =
        instancesForTask.find((instance) => instance.parentProcessInstanceId == null) ?? instancesForTask[0];
      const correlationName = rootProcessInstance?.processModelName;

      taskListWithCorrelation[rootProcessInstance.correlationId] = {
        correlation: rootProcessInstance.correlation!,
        correlationName: correlationName,
        correlationDescription: rootProcessInstance.correlation?.metadata?.description,
        taskList: tasks.filter((task) => task.correlationId === rootProcessInstance.correlationId),
      };
    }

    return Object.values(taskListWithCorrelation);
  }

  public applySort(sortBy: FlowNodeInstanceSortableColumns, sortDir: FlowNodeInstanceSortSettings['sortDir']): void {
    const searchParams = new URLSearchParams({ sortBy, sortDir: sortDir ?? SortDirection.DESC });
    this.props.history.replace({ search: searchParams.toString() });
  }

  private getCurrentSortSettings(): FlowNodeInstanceSortSettings {
    const searchParams = new URLSearchParams(this.props.location.search);
    const sortBy = (searchParams.get('sortBy') ??
      FlowNodeInstanceSortableColumns.createdAt) as FlowNodeInstanceSortableColumns;
    const sortDir = (searchParams.get('sortDir') ?? SortDirection.DESC) as SortDirection;

    return { sortBy, sortDir };
  }
}

function TableSortIndicator(props: {
  applySort: (...args: Parameters<typeof TaskListView.prototype.applySort>) => void;
  column: FlowNodeInstanceSortableColumns;
  sortBy: FlowNodeInstanceSortableColumns;
  sortDir: FlowNodeInstanceSortSettings['sortDir'];
  label: string | JSX.Element;
}): JSX.Element {
  const active = props.column === props.sortBy;
  const currentSortDirIsAsc = props.sortDir === SortDirection.ASC;
  const newSortDir = active ? (currentSortDirIsAsc ? SortDirection.DESC : SortDirection.ASC) : props.sortDir;

  return (
    <button className="group inline-flex" onClick={() => props.applySort(props.column, newSortDir)}>
      {props.label}
      <span
        className={classNames(
          active
            ? 'bg-gray-200 text-gray-900 group-hover:bg-gray-300'
            : 'invisible text-gray-400 group-hover:visible group-focus:visible',
          'ml-2 flex-none rounded'
        )}
      >
        {!currentSortDirIsAsc ? (
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        ) : (
          <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
        )}
      </span>
    </button>
  );
}

function EmptyState(props: any): JSX.Element {
  return (
    <div className="text-center mt-8">
      <EmojiHappyIcon className="h-10" />
      <h3 className="mt-2 text-sm font-medium text-[color:var(--text-color)]">{props.t('TaskList.EmptyStateTitle')}</h3>
      <p className="mt-1 text-sm text-[color:var(--text-brightest-color)]">{props.t('TaskList.EmptyStateBody')}</p>
    </div>
  );
}

export const TaskListViewWithRouter = withTranslation()(withRouter(TaskListView));
