import { User, UserManager, WebStorageStateStore } from 'oidc-client';
import jwtDecode from 'jwt-decode';

import { AuthorityConfig } from '@atlas-engine-contrib/atlas-ui_contracts';
import { IAuthService, IdentityWithEmailAndName, ProcessSigninResponseResult } from './IAuthService';
import { AuthorityUnreachableError, AuthorityUrlNotDefinedError, isConnectionError } from './InternalTypes';
import { OnIdentityChangedCallback } from '.';

type AuthToken = Record<'access_token' | 'scope' | 'expires_at' | 'expires_in' | 'token_type', string | null>;

export class AuthService implements IAuthService {
  private userManager!: UserManager;
  private isLoggedInFlag: boolean | undefined;
  private onIdentityChangedCallbacks = new Set<OnIdentityChangedCallback>();
  private userRefreshTimeout: number | null = null;
  private sessionId?: string;

  private readonly oauth2Config: AuthorityConfig;

  private constructor(oauth2Config: AuthorityConfig) {
    this.oauth2Config = oauth2Config;
  }

  public static async create(oauth2Config: AuthorityConfig): Promise<AuthService> {
    const authService = new AuthService(oauth2Config);
    await authService.initialize(oauth2Config);

    return authService;
  }

  public getGrantType(): AuthorityConfig['grantType'] {
    return this.oauth2Config.grantType;
  }

  public setSessionId(sessionId: string): void {
    this.sessionId = sessionId;
    this.getUser().then((user) => {
      if (user) {
        setCookie('anonymousSessionId', this.sessionId, user?.expires_in);
      }
    });
  }

  public async login(targetRoutingState?: unknown): Promise<void> {
    try {
      if (targetRoutingState != null) {
        localStorage.setItem('targetRoutingState', JSON.stringify(targetRoutingState));
      }

      if (this.oauth2Config.grantType === 'client_credentials') {
        window.location.assign('/authorize');
        return;
      }

      await this.userManager.signinRedirect();
    } catch (error) {
      throw this.handleAuthorityRequestError(error);
    }
  }

  public logout(): void {
    try {
      if (this.oauth2Config.grantType !== 'client_credentials') {
        this.userManager.signoutRedirect();
        return;
      }

      this.userManager.removeUser().then(() => this.detectLoggedInFlag());
    } catch (error) {
      throw this.handleAuthorityRequestError(error);
    }
  }

  public async processSigninResponse(): Promise<ProcessSigninResponseResult> {
    try {
      let user: User;
      if (this.oauth2Config.grantType === 'client_credentials') {
        const authToken = this.getAuthTokenFromUrl(window.location.href);
        this.createMachineUserByAuthToken(authToken);
        this.startSilentRenew();
        user = (await this.getUser()) as User;
      } else {
        try {
          user = (await this.userManager.signinCallback()) as User;
        } catch (error) {
          console.error(error);
          const currentUrl = new URL(window.location.href);
          const errorString = currentUrl.searchParams.get('error');
          if (errorString != null) {
            const error = new Error(errorString);
            throw error;
          }
          throw error;
        }
      }

      this.isLoggedInFlag = true;
      this.userManager.events.load(user);

      const identity = this.mapUserToIdentity(user);
      let parsedTargetRoutingState;

      const targetRoutingState = localStorage.getItem('targetRoutingState');
      if (targetRoutingState) {
        localStorage.removeItem('targetRoutingState');
        parsedTargetRoutingState = JSON.parse(targetRoutingState);
      }

      return {
        identity: identity,
        targetRoute: parsedTargetRoutingState ?? '/',
      };
    } catch (error) {
      throw this.handleAuthorityRequestError(error);
    }
  }

  public async processSignoutResponse(): Promise<void> {
    try {
      await this.userManager.signoutCallback();
    } catch (error) {
      throw this.handleAuthorityRequestError(error);
    }
  }

  public async detectLoggedInFlag(): Promise<boolean> {
    try {
      const user = await this.getUser();
      if (!user || user.expired || (this.oauth2Config.grantType === 'client_credentials' && !this.sessionId)) {
        this.isLoggedInFlag = false;
        this.handleUserUnloadedEvent();
        return this.isLoggedInFlag;
      }
      this.isLoggedInFlag = true;
      this.handleUserLoadedEvent(user);
    } catch (error) {
      this.handleUserUnloadedEvent();
      this.isLoggedInFlag = false;
    }
    return this.isLoggedInFlag;
  }

  public async getIdentity(): Promise<IdentityWithEmailAndName> {
    try {
      const user = await this.getUser();

      if (!user) {
        throw new Error('not logged in');
      }

      return this.mapUserToIdentity(user);
    } catch (error) {
      throw this.handleAuthorityRequestError(error);
    }
  }

  public isLoggedIn(): boolean {
    return !!this.isLoggedInFlag;
  }

  public async hasClaim(claim: string): Promise<boolean> {
    try {
      const user = await this.getUser();

      if (!user || !user.access_token || user.access_token === '') {
        return false;
      }

      const decodedAccessToken = jwtDecode<Record<string, unknown>>(user.access_token);

      if (!decodedAccessToken) {
        return false;
      }

      return decodedAccessToken[claim] != undefined;
    } catch (error) {
      throw this.handleAuthorityRequestError(error);
    }
  }

  public onIdentityChanged(callback: OnIdentityChangedCallback): void {
    this.onIdentityChangedCallbacks.add(callback);
  }

  public removeOnIdentityChanged(callback: OnIdentityChangedCallback): void {
    this.onIdentityChangedCallbacks.delete(callback);
  }

  private async initialize(oauth2Config: AuthorityConfig): Promise<void> {
    if (oauth2Config.grantType === 'client_credentials') {
      this.userManager = new UserManager({
        authority: this.oauth2Config.authority,
        client_id: this.oauth2Config.clientId,
      });
      const sessionId = getCookie('anonymousSessionId');
      this.sessionId = sessionId;
      const user = await this.getUser();
      if (user && !user.expired) {
        this.startSilentRenew();
      }
      return;
    }

    const stateStorage = new WebStorageStateStore({ store: window.sessionStorage });
    const allKeys = await stateStorage.getAllKeys();
    const activeSession = allKeys.find((key) => key.endsWith(`:${oauth2Config.clientId}`));

    if (oauth2Config.authority == null && activeSession == null) {
      throw new AuthorityUrlNotDefinedError();
    }

    const authorityUrlFromActiveSession = activeSession?.replace('user:', '').replace(`:${oauth2Config.clientId}`, '');
    const authority = oauth2Config.authority ?? authorityUrlFromActiveSession;

    this.userManager = new UserManager({
      authority: authority,
      client_id: oauth2Config.clientId,
      redirect_uri: `${oauth2Config.redirectBasePath}/signin-oidc`,
      silent_redirect_uri: `${oauth2Config.redirectBasePath}/signin-oidc`,
      post_logout_redirect_uri: `${oauth2Config.redirectBasePath}/signout-oidc`,
      loadUserInfo: false,
      response_type: oauth2Config.responseType,
      scope: `openid profile email ${oauth2Config.scope}`,
    });

    this.userManager.events.addUserLoaded(this.handleUserLoadedEvent.bind(this));
    this.userManager.events.addUserUnloaded(this.handleUserUnloadedEvent.bind(this));
    this.userManager.events.addSilentRenewError(async () => {
      await this.userManager.clearStaleState();
      await this.userManager.removeUser();
      await this.detectLoggedInFlag();
    });

    this.startSilentRenew();
  }

  private handleUserLoadedEvent(user: User): void {
    const identity = this.mapUserToIdentity(user);

    for (const callback of this.onIdentityChangedCallbacks) {
      callback(identity);
    }
  }

  private handleUserUnloadedEvent(): void {
    for (const callback of this.onIdentityChangedCallbacks) {
      callback(null);
    }
  }

  private handleAuthorityRequestError(error: any): void {
    if (isConnectionError(error)) {
      throw new AuthorityUnreachableError(error.code);
    }

    throw error;
  }

  private mapUserToIdentity(user: User): IdentityWithEmailAndName {
    return {
      token: user.access_token,
      userId: user.profile.sub,
      email: user.profile.email,
      name: user.profile.name,
      anonymousSessionId: this.sessionId,
    };
  }

  private async getUser(): Promise<User | null> {
    const user = await this.userManager.getUser();

    return user;
  }

  private createMachineUserByAuthToken(authToken: any): void {
    const decodedAccessToken = jwtDecode<Record<string, any>>(authToken.access_token);
    const user = new User({
      access_token: authToken.access_token,
      token_type: authToken.token_type,
      id_token: '',
      refresh_token: '',
      scope: authToken.scope,
      expires_at: authToken.expires_at,
      profile: {
        iss: decodedAccessToken.iss,
        exp: decodedAccessToken.exp,
        iat: decodedAccessToken.iat,
        sub: decodedAccessToken.sub,
        aud: decodedAccessToken.aud,
      },
      state: null,
      session_state: '',
    });

    this.userManager.storeUser(user);
    this.handleUserLoadedEvent(user);
  }

  private async getAuthTokenFromServer(): Promise<AuthToken | undefined> {
    try {
      const response = await fetch(`${process.env.PUBLIC_URL}/authorize`);
      if (response.status === 200) {
        const authToken = this.getAuthTokenFromUrl(response.url);
        return authToken;
      }
    } catch (error) {
      console.error('error while getAuthTokenFromServer', error);
    }
  }

  private startSilentRenew(): void {
    if (this.oauth2Config.grantType !== 'client_credentials') {
      return this.userManager.startSilentRenew();
    }

    this.getUser().then((user) => {
      if (!user) {
        return;
      }

      const refreshTimeout = user.expires_in * 0.85 * 1000;

      this.userRefreshTimeout = window.setTimeout(async () => {
        let newAuthToken = await this.getAuthTokenFromServer();

        if (!newAuthToken) {
          this.logout();
          return;
        }

        this.createMachineUserByAuthToken(newAuthToken);
        const newUser = await this.getUser();
        if (newUser) {
          setCookie('anonymousSessionId', this.sessionId, newUser?.expires_in);
        }
        this.userRefreshTimeout = null;
        this.startSilentRenew();
      }, refreshTimeout);
    });
  }

  private getAuthTokenFromUrl(url: string): AuthToken {
    const currentUrl = new URL(url);
    const errorString = currentUrl.searchParams.get('error');
    if (errorString != null) {
      const error = new Error(errorString);
      throw error;
    }

    const authToken = {
      access_token: currentUrl.searchParams.get('access_token'),
      scope: currentUrl.searchParams.get('scope'),
      expires_at: currentUrl.searchParams.get('expires_at'),
      expires_in: currentUrl.searchParams.get('expires_in'),
      token_type: currentUrl.searchParams.get('token_type'),
    };

    return authToken;
  }
}

function setCookie(cookieName: string, cookieValue: string | undefined, timeInSeconds: number) {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime());
  expirationDate.setSeconds(expirationDate.getSeconds() + timeInSeconds);

  const expires = `expires=${cookieValue ? expirationDate.toUTCString() : new Date(0).toUTCString()}`;
  const cookie = `${cookieName}=${cookieValue ?? ''};${expires};path=/`;
  document.cookie = cookie;
}

function getCookie(cookieName: string): string | undefined {
  const cookie = document.cookie
    .split(';')
    .map((cookie) => cookie.trim())
    .find((cookie) => cookie.startsWith(cookieName));

  return cookie?.replace(`${cookieName}=`, '');
}
