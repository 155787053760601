import { Component, createRef, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { StartableGroupConfig } from '@atlas-engine-contrib/atlas-ui_contracts';

import { EngineService } from '../../../lib';
import { ErrorRenderer } from '../ErrorRenderer';
import { ProcessModel } from './ProcessModel';
import { Startable } from './StartableList';
import { StartDialog } from './StartDialog';
import { WithTranslation, withTranslation } from 'react-i18next';

type StartableGroupProps = {
  group: StartableGroupConfig;
  startables: Array<Startable>;
  engineService: EngineService;
} & RouteComponentProps &
  WithTranslation;

type StartableGroupState = {
  lastProcessStartError?: Error;
};

export class StartableGroupComponent extends Component<StartableGroupProps, StartableGroupState> {
  private startProcessAndNavigateToProcessBound = this.startProcessAndNavigateToProcess.bind(this);
  private navigateToStartDialogBound = this.navigateToStartDialog.bind(this);
  private errorAlertRef = createRef<HTMLDivElement>();

  constructor(props: StartableGroupProps) {
    super(props);

    this.state = {};
  }

  public render(): JSX.Element {
    const { group, startables } = this.props;
    const safeGroupId = group.id.trim().replaceAll(' ', '-');
    const startableComponents = startables.map(this.mapStartableToComponent.bind(this));

    return (
      <Fragment>
        <div className={`startable-group startable-group--${safeGroupId} group ${!group.title ? 'last:mt-20' : ''}`}>
          {this.state.lastProcessStartError && (
            <div className="mb-4" ref={this.errorAlertRef}>
              <ErrorRenderer error={this.state.lastProcessStartError} />
            </div>
          )}
          {group.title && (
            <h2 className="startable-group__title group-only:hidden">
              {this.props.t(`StartableGroups.${group.id}`, { defaultValue: group.title })}
            </h2>
          )}
          <ul role="list" className="startable-group__startables">
            {startableComponents}
          </ul>
        </div>
      </Fragment>
    );
  }

  private mapStartableToComponent(startable: Startable): JSX.Element {
    switch (startable.type) {
      case 'processModel':
        const processModelTitle = this.props.t(`Startables.${startable.config.id}.Title`, {
          defaultValue: startable.config.title ?? '',
        });
        const processModelBody = this.props.t(`Startables.${startable.config.id}.Body`, {
          defaultValue: startable.config.body ?? '',
        });

        const translatedStartButtonTitles: { [startEventId: string]: string } = {};

        Object.entries(startable.config.startButtonTitles).forEach((entry, index) => {
          const key = entry[0];
          const value = entry[1];

          const translatedValue = this.props.t(`Startables.${startable.config.id}.StartButtonTitles.${key}`, {
            defaultValue: value,
          });

          const noTranslationForValue = translatedValue === value;
          if (index === 0 && noTranslationForValue) {
            translatedStartButtonTitles[key] = this.props.t(`Startables.${startable.config.id}.StartButtonTitle`, {
              defaultValue: value,
            });
            return;
          }

          translatedStartButtonTitles[key] = translatedValue;
        });

        return (
          <ProcessModel
            key={startable.config.id}
            id={startable.config.id}
            onStart={this.startProcessAndNavigateToProcessBound}
            startButtonTitles={translatedStartButtonTitles}
            title={processModelTitle}
            body={processModelBody}
          />
        );
      case 'startDialog':
        const startDialogTitle = this.props.t(`Startables.${startable.config.id}.Title`, {
          defaultValue: startable.config.title ?? '',
        });
        const startDialogBody = this.props.t(`Startables.${startable.config.id}.Body`, {
          defaultValue: startable.config.body ?? '',
        });
        const startDialogStartButtonTitle = this.props.t(`Startables.${startable.config.id}.StartButtonTitle`, {
          defaultValue: startable.config.startButtonTitle ?? '',
        });

        return (
          <StartDialog
            key={startable.config.id}
            body={startDialogBody}
            onStart={this.navigateToStartDialogBound}
            startButtonTitle={startDialogStartButtonTitle}
            startDialogId={startable.config.id}
            title={startDialogTitle}
            url={startable.config.url}
          />
        );
      default:
        throw new Error('Unknown startable type encountered');
    }
  }

  private async startProcessAndNavigateToProcess(processModelId: string, startEventId?: string): Promise<void> {
    try {
      const processInstance = await this.props.engineService.startProcessInstance(
        processModelId,
        undefined,
        startEventId
      );
      this.props.history.push(`/correlation/${processInstance.correlationId}`, {
        processInstanceId: processInstance.processInstanceId,
      });
    } catch (error) {
      this.setState({ lastProcessStartError: error }, () => {
        if (this.errorAlertRef.current != null) {
          this.errorAlertRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  }

  private navigateToStartDialog(startDialogId: string): void {
    this.props.history.push(`/startdialog/${startDialogId}`);
  }
}

export const StartableGroup = withTranslation()(withRouter(StartableGroupComponent));
