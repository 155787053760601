import '../style.scss';

import { Component, createRef, useEffect, useRef, useState } from 'react';
import { WithTranslation, useTranslation, withTranslation } from 'react-i18next';
import { BrowserRouter, Route, RouteComponentProps, Switch, useHistory, useParams } from 'react-router-dom';

import { IFormStatePersistenceService, PortalConfiguration } from '@atlas-engine-contrib/atlas-ui_contracts';

import {
  AuthorityUnreachableError,
  AuthService,
  AuthServiceDummy,
  AuthorityUrlNotDefinedError,
  ComponentStatePersistenceService,
  ConfigurationService,
  EngineContext,
  EngineService,
  EngineUnreachableError,
  ExtensionService,
  IAuthService,
  isVersionGreaterEquals,
  parseVersion,
} from '../lib/index';
import { Layout, LayoutContent, LayoutHeader } from './Layout';
import { ExtensionServiceProvider, IdentityProvider, useIdentity } from './context';
import { GenericViewProps } from './GenericViewProps';
import { StartableListViewWithRouter } from './views/startable-list-view/StartableListView';
import { TaskListViewWithRouter } from './views/task-list-view/TaskListView';
import { TaskViewWithRouter } from './views/task-view/TaskViewWithRouter';
import { CorrelationTrackerViewWithRouter } from './views/correlation-tracker-view/CorrelationTrackerViewWithRouter';
import { StartDialogHomepage, StartDialogViewWithRouter } from './views/start-dialog-view/StartDialogView';
import { StartProcessViaUrlView } from './views/start-process-via-url-view/StartProcessViaUrlView';
import { UserSettingsViewWithRouter } from './views/user-settings-view/UserSettingsView';
import { PrivateRoute } from './PrivateRoute';
import { registerWebComponents } from '@atlas-engine-contrib/atlas-ui_user-interaction-component';
import { ErrorRenderer } from './components/ErrorRenderer';
import { LanguageService } from '../lib/LanguageService';
import { NotFoundViewWithRouter } from './views/404/404';
import Alert from './components/Alert';
import { DelayedRenderer } from './components/DelayedRenderer';
import LoadingSpinner from './components/LoadingSpinner';

type AppBootstrapperProps = WithTranslation & {
  resolveAuthServiceForLogEmitter: (value: IAuthService | PromiseLike<IAuthService>) => void;
  loadingSpinner: { element: HTMLSpanElement | null; isActive: boolean };
};

type AuthSigninCallbackProps = GenericViewProps & {
  authService: IAuthService;
  engineService: EngineService;
};

type AuthSignoutCallbackProps = GenericViewProps & {
  authService: IAuthService;
};

type RouteEntry = {
  path: string;
  activeNav?: string;
  exact?: boolean;
  loginRequired: boolean;
  main: (props: GenericViewProps) => JSX.Element;
};

export type AppBootstrapperState = {
  appConfig: PortalConfiguration | null;
  error: Error | null;
  willReload: boolean;
  engineVersion?: string;
  loadingSpinnerActiveOnInitialAccess: boolean;
};

registerWebComponents();

class AppBootstrapper extends Component<AppBootstrapperProps, AppBootstrapperState> {
  private configurationService: ConfigurationService;
  private extensionService!: ExtensionService;
  private languageService!: LanguageService;
  private authService!: IAuthService;
  private routes: Array<RouteEntry>;
  private componentStatePersistenceService!: IFormStatePersistenceService;
  private engineService!: EngineService;

  private readonly minRequiredEngineVersion = '11.0.0';
  private browserRouterRef = createRef<BrowserRouter>();

  constructor(props: AppBootstrapperProps) {
    super(props);

    this.configurationService = new ConfigurationService();

    this.state = {
      appConfig: null,
      error: null,
      willReload: false,
      loadingSpinnerActiveOnInitialAccess: this.props.loadingSpinner.isActive,
    };

    this.routes = [
      {
        path: '/',
        exact: true,
        loginRequired: true,
        main: (genericViewProps: GenericViewProps): JSX.Element => {
          if (
            this.state.appConfig?.useStartDialogAsHomepage != null &&
            this.state.appConfig?.useStartDialogAsHomepage.trim() !== ''
          ) {
            return (
              <StartDialogHomepage
                {...genericViewProps}
                startDialogId={this.state.appConfig?.useStartDialogAsHomepage}
                config={this.state.appConfig?.startDialogs as any}
                authService={this.authService}
                languageService={this.languageService}
              />
            );
          }

          return (
            <StartableListViewWithRouter
              {...genericViewProps}
              engineService={this.engineService}
              authService={this.authService}
              startDialogsConfig={this.state.appConfig?.startDialogs as any}
              startablesOrder={this.state.appConfig?.startablesOrder}
              startableGroups={this.state.appConfig?.startableGroups}
            />
          );
        },
      },
      {
        path: '/startable-list',
        exact: true,
        loginRequired: true,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <StartableListViewWithRouter
            {...genericViewProps}
            engineService={this.engineService}
            authService={this.authService}
            startDialogsConfig={this.state.appConfig?.startDialogs as any}
            startablesOrder={this.state.appConfig?.startablesOrder}
            startableGroups={this.state.appConfig?.startableGroups}
          />
        ),
      },
      {
        path: '/task-list',
        exact: true,
        loginRequired: true,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <TaskListViewWithRouter {...genericViewProps} engineService={this.engineService} />
        ),
      },
      {
        path: '/task/:correlationId/:processInstanceId/:flowNodeInstanceId',
        loginRequired: true,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <TaskViewWithRouter
            {...genericViewProps}
            componentStatePersistenceService={this.componentStatePersistenceService}
            taskViewConfig={this.state.appConfig?.taskViewConfig as any}
            languageService={this.languageService}
          />
        ),
      },
      {
        path: '/correlation/:correlationId/',
        loginRequired: true,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <CorrelationTrackerViewWithRouter {...genericViewProps} />
        ),
      },
      {
        path: '/startdialog/:startDialogId/:payload*',
        loginRequired: true,
        main: (genericViewProps: GenericViewProps): JSX.Element => {
          const { startDialogId, payload } = useParams<any>();

          return (
            <StartDialogViewWithRouter
              key={`${startDialogId}_${payload}`}
              {...genericViewProps}
              config={this.state.appConfig?.startDialogs as any}
              authService={this.authService}
              languageService={this.languageService}
              startDialogId={startDialogId}
              payload={payload}
            />
          );
        },
      },
      {
        path: '/start/:processModelId/:payload*',
        loginRequired: true,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <StartProcessViaUrlView {...genericViewProps} engineService={this.engineService} />
        ),
      },
      {
        path: '/user-settings',
        exact: true,
        loginRequired: false,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <UserSettingsViewWithRouter
            {...genericViewProps}
            authService={this.authService}
            languageService={this.languageService}
          />
        ),
      },
      {
        path: '/signin-oidc',
        loginRequired: false,
        main: (genericViewProps: GenericViewProps): JSX.Element => (
          <AuthSigninCallback {...genericViewProps} authService={this.authService} engineService={this.engineService} />
        ),
      },
      {
        path: '/signout-oidc',
        loginRequired: false,
        main: (genericViewProps): JSX.Element => (
          <AuthSignoutCallback authService={this.authService} {...genericViewProps} />
        ),
      },
      {
        path: '*',
        loginRequired: false,
        main: (genericViewProps: GenericViewProps): JSX.Element => <NotFoundViewWithRouter {...genericViewProps} />,
      },
    ];
  }

  public async componentDidMount(): Promise<void> {
    const unregisterCallback = (this.browserRouterRef.current as BrowserRouter & RouteComponentProps).history.listen(
      (location, action) => {
        this.setState({ loadingSpinnerActiveOnInitialAccess: false }, unregisterCallback);
      }
    );

    try {
      const appConfig = await this.configurationService.loadConfig();
      this.setState({ appConfig: appConfig });

      if (appConfig.favicon.trim() !== '') {
        this.addFavicon(appConfig.favicon);
      }

      this.languageService = new LanguageService(appConfig);
      this.extensionService = new ExtensionService(appConfig, this.languageService);
      await this.extensionService.loadExtensions();

      this.authService = appConfig.useAuthority
        ? await AuthService.create(appConfig.authorityConfiguration)
        : new AuthServiceDummy();
      this.props.resolveAuthServiceForLogEmitter(this.authService);

      this.componentStatePersistenceService = new ComponentStatePersistenceService(this.authService);
      this.engineService = new EngineService(this.authService, appConfig);
      await this.authService.detectLoggedInFlag();
      const engineVersion = await this.engineService.getEngineVersion();
      this.setState({ engineVersion: engineVersion }, () => {
        this.props.loadingSpinner.element?.parentElement?.style.setProperty('display', 'none');
      });
    } catch (error) {
      this.setState(
        {
          error: error,
        },
        () => {
          this.props.loadingSpinner.element?.parentElement?.style.setProperty('display', 'none');
        }
      );
    }
  }

  public componentDidUpdate(): void {
    const { error, willReload } = this.state;

    if (error && !willReload) {
      if (
        error instanceof AuthorityUnreachableError ||
        error instanceof EngineUnreachableError ||
        error instanceof AuthorityUrlNotDefinedError
      ) {
        this.reloadPageDeferred();
      }
    }

    if (this.props.tReady) {
      document.title = this.props.t('ApplicationTitle');
    }
  }

  public render(): JSX.Element | null {
    const { t } = this.props;
    const logo = this.state.appConfig ? this.state.appConfig.logo : '';

    const genericViewProps: GenericViewProps = {
      logo: logo,
      loadingSpinnerActiveOnInitialAccess: this.state.loadingSpinnerActiveOnInitialAccess,
    };

    const engineVersionIncompatibleMessage =
      this.isEngineVersionCompatible() === false ? (
        <Alert variant="warning" dismissible={true}>
          <Alert.Body>{t('EngineOutdated', { version: this.minRequiredEngineVersion })}</Alert.Body>
        </Alert>
      ) : null;

    return (
      <ExtensionServiceProvider extensionService={this.extensionService}>
        <IdentityProvider authService={this.authService}>
          <EngineContext.Provider value={this.engineService}>
            {engineVersionIncompatibleMessage}
            <BrowserRouter ref={this.browserRouterRef}>
              <Switch>
                {this.routes.map((route, index) => {
                  if (route.loginRequired) {
                    return (
                      <PrivateRoute
                        bootstrapError={this.state.error != null}
                        authService={this.authService}
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        {...genericViewProps}
                      >
                        {this.state.error ? (
                          <BootstrapError
                            {...genericViewProps}
                            error={this.state.error}
                            willReload={this.state.willReload}
                          />
                        ) : (
                          <route.main {...genericViewProps} />
                        )}
                      </PrivateRoute>
                    );
                  }

                  const isUserSettingsPage = route.path === '/user-settings';
                  return (
                    <Route key={index} path={route.path} exact={route.exact}>
                      {isUserSettingsPage || !this.state.error ? (
                        <route.main {...genericViewProps} />
                      ) : (
                        <BootstrapError
                          {...genericViewProps}
                          error={this.state.error}
                          willReload={this.state.willReload}
                        />
                      )}
                    </Route>
                  );
                })}
              </Switch>
            </BrowserRouter>
          </EngineContext.Provider>
        </IdentityProvider>
      </ExtensionServiceProvider>
    );
  }

  private reloadPageDeferred(): void {
    this.setState({ willReload: true });

    setTimeout(() => {
      window.location.reload();
    }, 30 * 1000);
  }

  private addFavicon(faviconUrl: string): void {
    const favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = faviconUrl;

    document.head.appendChild(favicon);
  }

  private isEngineVersionCompatible(): boolean | null {
    if (!this.state.engineVersion) {
      return null;
    }

    const parsedEngineVersion = parseVersion(this.state.engineVersion);
    if (!parsedEngineVersion) {
      console.warn('Could not parse 5Minds Engine version.');
      return null;
    }

    const parsedMinRequiredEngineVersion = parseVersion(this.minRequiredEngineVersion);
    if (!parsedMinRequiredEngineVersion) {
      console.warn('Could not parse minimum required 5Minds Engine version.');
      return null;
    }

    return isVersionGreaterEquals(parsedEngineVersion, parsedMinRequiredEngineVersion);
  }
}

export const TranslatedAppBootstrapper = withTranslation()(AppBootstrapper);

/**
 * This component is rendered when the user signed in and is being redirected by the OpenID provider.
 */
function AuthSigninCallback(props: AuthSigninCallbackProps): JSX.Element | null {
  const { authService, engineService } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const { setIdentity } = useIdentity();
  const [error, setError] = useState<Error | null>(null);
  const loadingSpinnerRef = useRef();

  useEffect(() => {
    authService
      ?.processSigninResponse()
      .then(async (result) => {
        await engineService.createAnonymousSessionIfNecessary();
        const identity = await authService.getIdentity();
        setIdentity(identity);
        history.replace(result.targetRoute as any, { loadingSpinnerActive: loadingSpinnerRef.current != null });
      })
      .catch(async (err) => {
        await authService.detectLoggedInFlag();
        setError(err);
      });
  }, [authService]);

  const handleTryAgain = (event: any): void => {
    event.preventDefault();
    authService.login();
  };

  return (
    <Layout>
      <LayoutHeader logo={props.logo} />
      {error == null && (
        <DelayedRenderer timeoutInMs={props.loadingSpinnerActiveOnInitialAccess ? 0 : undefined}>
          <LoadingSpinner htmlRef={loadingSpinnerRef} style={{ gridArea: 'content' }} />
        </DelayedRenderer>
      )}
      <LayoutContent>
        <div className="signin-redirect-view">
          {error != null && (
            <Alert variant="danger">
              <Alert.Heading>{t('Auth.ErrorDuringLogin')}</Alert.Heading>
              <Alert.Body>
                <pre className="mt-2">{error.message}</pre>
                <p className="mt-4">
                  <Alert.Link href="" onClick={handleTryAgain}>
                    {t('TryAgain')}
                  </Alert.Link>
                </p>
              </Alert.Body>
            </Alert>
          )}
        </div>
      </LayoutContent>
    </Layout>
  );
}

/**
 * This component is rendered when the user signed out and is being redirected by the OpenID provider.
 */
function AuthSignoutCallback(props: AuthSignoutCallbackProps): JSX.Element {
  const { authService } = props;
  const history = useHistory();
  const { setIdentity } = useIdentity();
  const loadingSpinnerRef = useRef();

  useEffect(() => {
    authService?.processSignoutResponse().then(() => {
      setIdentity(null);
      history.replace('/', { loadingSpinnerActive: loadingSpinnerRef.current != null });
    });
  }, [authService]);

  return (
    <Layout id="signout-redirect-view">
      <LayoutHeader logo={props.logo} />
      <DelayedRenderer timeoutInMs={props.loadingSpinnerActiveOnInitialAccess ? 0 : undefined}>
        <LoadingSpinner htmlRef={loadingSpinnerRef} style={{ gridArea: 'content' }} />
      </DelayedRenderer>
    </Layout>
  );
}

function BootstrapError(props: GenericViewProps & { error: Error; willReload: boolean }): JSX.Element {
  const { t } = useTranslation();

  return (
    <Layout>
      <LayoutHeader logo={props.logo} />
      <LayoutContent>
        <div className="bootstrap-error">
          <ErrorRenderer error={props.error} />
          {props.willReload && (
            <Alert variant="info" className="mt-4">
              {t('PageWillRefreshAutomatically')}
            </Alert>
          )}
        </div>
      </LayoutContent>
    </Layout>
  );
}
