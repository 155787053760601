import { Component, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Subscription } from '@atlas-engine/atlas_engine_sdk';
import { ProcessModelConfig, StartDialogsConfig, StartableGroupConfig } from '@atlas-engine-contrib/atlas-ui_contracts';

import { EngineService, IAuthService, getAllowedStartDialogs } from '../../../lib';
import { DelayedRenderer } from '../../components/DelayedRenderer';
import { ErrorRenderer } from '../../components/ErrorRenderer';
import { StartableList } from '../../components/startable-list/StartableList';
import { Layout, LayoutContent, LayoutHeader } from '../../Layout';
import { GenericViewProps } from '../../GenericViewProps';
import Alert from '../../components/Alert';
import LoadingSpinner from '../../components/LoadingSpinner';

export type StartableListViewProps = {
  engineService: EngineService;
  authService: IAuthService;
  startDialogsConfig: StartDialogsConfig;
  startablesOrder?: Array<string>;
  startableGroups?: Array<StartableGroupConfig>;
} & RouteComponentProps &
  WithTranslation &
  GenericViewProps;

export type StartableListViewState = {
  processModels?: Array<ProcessModelConfig>;
  startDialogs?: StartDialogsConfig;
  loadingError?: Error;
  correlationIsFinished: boolean;
  isLoading: boolean;
  showLoadingSpinnerOnInitialRender: boolean;
};

class StartableListView extends Component<StartableListViewProps, StartableListViewState> {
  constructor(props: StartableListViewProps) {
    super(props);

    const correlationFinishedParam = new URLSearchParams(this.props.location.search).get('lastActiveCorrelation');

    this.state = {
      isLoading: true,
      correlationIsFinished: correlationFinishedParam != null && correlationFinishedParam !== '',
      showLoadingSpinnerOnInitialRender:
        (this.props.location as any).state?.loadingSpinnerActive === true ||
        this.props.loadingSpinnerActiveOnInitialAccess,
    };
  }

  private deployedProcessesChangedSubscriptions: Array<Subscription> = [];

  public async componentDidMount(): Promise<void> {
    try {
      await this.loadProcessModels();
      await this.loadStartDialogs();
      this.setState({ isLoading: false });

      this.deployedProcessesChangedSubscriptions = await this.props.engineService.onDeployedProcessesChanged(
        this.loadProcessModels.bind(this)
      );
    } catch (error) {
      this.setState({ loadingError: error, isLoading: false });
    }
  }

  public async componentWillUnmount(): Promise<void> {
    await this.props.engineService.removeSubscriptions(this.deployedProcessesChangedSubscriptions);
  }

  public render(): JSX.Element {
    const loadingError = this.state.loadingError ? <ErrorRenderer error={this.state.loadingError} /> : null;
    const processCompletedMessage = this.state.correlationIsFinished ? (
      <Alert variant="success" dismissible={true} className="mb-4">
        <Alert.Body>{this.props.t('StartableList.ProcessCompletedSuccessfully')}</Alert.Body>
      </Alert>
    ) : null;

    const startableList = this.state.processModels && this.state.startDialogs && (
      <StartableList
        processModels={this.state.processModels}
        startDialogs={this.state.startDialogs}
        startablesOrder={this.props.startablesOrder}
        startableGroups={this.props.startableGroups}
        engineService={this.props.engineService}
      />
    );

    const header = this.props.t('StartableList.Header', { defaultValue: '' });
    const description = this.props.t('StartableList.Description', { defaultValue: '' });

    return (
      <Layout>
        <LayoutHeader activeNav="startable-list" logo={this.props.logo} />
        {this.state.isLoading && (
          <DelayedRenderer timeoutInMs={this.state.showLoadingSpinnerOnInitialRender ? 0 : undefined}>
            <LoadingSpinner style={{ gridArea: 'content' }} />
          </DelayedRenderer>
        )}

        <LayoutContent>
          <div className="startable-list-view">
            {processCompletedMessage}
            {loadingError ? (
              loadingError
            ) : (
              <Fragment>
                {(header || description) && (
                  <div className="flex justify-center mb-8">
                    <div className="text-center max-w-lg">
                      {header && <h2 className="mt-2 text-lg font-medium text-[color:var(--text-color)]">{header}</h2>}
                      {description && (
                        <p className="mt-1 text-sm text-[color:var(--text-brightest-color)]">{description}</p>
                      )}
                    </div>
                  </div>
                )}
                {!this.state.isLoading && startableList}
              </Fragment>
            )}
          </div>
        </LayoutContent>
      </Layout>
    );
  }

  private async loadProcessModels(): Promise<void> {
    const processModels = await this.props.engineService.getProcessModels();

    this.setState({
      processModels: processModels,
    });
  }

  private async loadStartDialogs(): Promise<void> {
    const { startDialogsConfig, authService } = this.props;
    const startDialogs = await getAllowedStartDialogs(startDialogsConfig, authService);

    this.setState({
      startDialogs: startDialogs,
    });
  }
}

export const StartableListViewWithRouter = withTranslation()(withRouter(StartableListView));
