import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import { useIdentity } from '../context';
import { Link } from 'react-router-dom';
import { classNames } from '../../lib';
import { RenderHooks } from './RenderHooks';

type AppHeaderProps = {
  logo: string;
  activeNav?: string;
};

export function AppHeader(props: AppHeaderProps): JSX.Element {
  const { t } = useTranslation();
  const { identity, authService } = useIdentity();

  const isLoggedIn = identity != null;
  const title = t('Header.Title', { defaultValue: '' });
  const hasLogo = props.logo !== '';
  const settingsLabel = t('Header.TitleUserSettings');

  return (
    <Disclosure
      as="nav"
      className="bg-[color:var(--app-header-background-color)] shadow shadow-[color:var(--shadow-color)] mb-px testtest"
    >
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex cursor-pointer">
                <RenderHooks id="app.header.logo.before" />
                <Link to="/" replace={true} className="flex-shrink-0 flex items-center">
                  {hasLogo && <img className="block h-12 w-auto" src={props.logo} alt={title} />}
                </Link>
                <RenderHooks id="app.header.logo.after" />

                <span className="hidden lg:flex cursor-pointer">
                  <RenderHooks id="app.header.title.before" />
                  <Link to="/" replace={true} className="flex-shrink-0 flex items-center">
                    {title && <span className="inline-flex items-center h-8 w-auto">{title}</span>}
                  </Link>
                  <RenderHooks id="app.header.title.after" />
                </span>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                {isLoggedIn && (
                  <Fragment>
                    <RenderHooks id="app.header.notification.before" />
                    <Link
                      to="/task-list"
                      component={(props) => {
                        const { navigate, href } = props;

                        return (
                          <button
                            onClick={() => navigate(href)}
                            type="button"
                            className="bg-[color:var(--app-header-background-color)] p-1 rounded-full text-[color:var(--app-header-notification-icon-color)] hover:text-[color:var(--app-header-notification-icon-hover-color)] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[color:var(--app-header-background-color)] focus:ring-[color:var(--focus-color)]"
                          >
                            <span className="sr-only">View notifications</span>
                            <span className="inline-block relative">
                              <BellIcon className="h-6 w-6" aria-hidden="true" />
                              {/* if new tasks available */}
                              {false && (
                                <span className="absolute top-0 right-0 block h-2 w-2 rounded-full ring-2 ring-[color:var(--app-header-background-color)] bg-[color:var(--app-header-new-notification-indicator-color)]" />
                              )}
                            </span>
                          </button>
                        );
                      }}
                    ></Link>
                    <RenderHooks id="app.header.notification.after" />
                  </Fragment>
                )}
                {/* Profile dropdown */}
                <RenderHooks id="app.header.menu.before" />
                <Menu as="div" className="ml-3 relative">
                  <Menu.Button className="bg-[color:var(--app-header-background-color)] rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[color:var(--app-header-background-color)] focus:ring-[color:var(--focus-color)]">
                    <span className="sr-only">Open user menu</span>
                    <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-[color:var(--app-header-menu-button-background-color)]">
                      <svg
                        className="h-full w-full text-[color:var(--app-header-menu-button-text-color)]"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg shadow-[color:var(--shadow-color)] py-1 bg-[color:var(--dropdown-menu-background-color)] ring-1 ring-[color:rgb(var(--outline-color)/var(--tw-ring-opacity))] ring-opacity-5 focus:outline-none z-40">
                      {isLoggedIn && (
                        <div className="px-4 py-3 border-b border-[color:var(--dropdown-menu-entry-divider-color)]">
                          <p className="text-sm">{t('Header.LoggedIn')}</p>
                          <p className="text-sm font-medium text-[color:var(--text-color)] truncate">
                            {identity?.name ?? 'Unnamed User'}
                          </p>
                        </div>
                      )}
                      {isLoggedIn && (
                        <Fragment>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/"
                                className={classNames(
                                  active
                                    ? 'bg-[color:var(--dropdown-menu-entry-background-hover-color)] text-[color:var(--text-color)]'
                                    : 'text-[color:var(--text-bright-color)]',
                                  'block px-4 py-2 text-sm'
                                )}
                              >
                                {t('Header.TitleStartableList')}
                              </Link>
                            )}
                          </Menu.Item>

                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/task-list"
                                className={classNames(
                                  active
                                    ? 'bg-[color:var(--dropdown-menu-entry-background-hover-color)] text-[color:var(--text-color)]'
                                    : 'text-[color:var(--text-bright-color)]',
                                  'block px-4 py-2 text-sm border-b border-[color:var(--dropdown-menu-entry-divider-color)]'
                                )}
                              >
                                {t('Header.TitleTaskList')}
                              </Link>
                            )}
                          </Menu.Item>
                        </Fragment>
                      )}
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/user-settings"
                            className={classNames(
                              active
                                ? 'bg-[color:var(--dropdown-menu-entry-background-hover-color)] text-[color:var(--text-color)]'
                                : 'text-[color:var(--text-bright-color)]',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            {settingsLabel}
                          </Link>
                        )}
                      </Menu.Item>
                      {isLoggedIn && (
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="#"
                              onClick={() => authService?.logout()}
                              className={classNames(
                                active
                                  ? 'bg-[color:var(--dropdown-menu-entry-background-hover-color)] text-[color:var(--text-color)]'
                                  : 'text-[color:var(--text-bright-color)]',
                                'block px-4 py-2 text-sm'
                              )}
                            >
                              {t('Header.Logout')}
                            </Link>
                          )}
                        </Menu.Item>
                      )}
                    </Menu.Items>
                  </Transition>
                </Menu>
                <RenderHooks id="app.header.menu.after" />
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <RenderHooks id="app.header.menu.before" />
                <Disclosure.Button className="group inline-flex items-center justify-center p-2 rounded-md text-[color:var(--app-header-mobile-menu-button-text-color)] hover:text-[color:var(--app-header-mobile-menu-button-hover-color)] hover:bg-[color:var(--app-header-mobile-menu-button-background-hover-color)] focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[color:var(--focus-color)]">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <span className="inline-block relative">
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                      {/* if new tasks available */}
                      {false && (
                        <span className="absolute top-0 right-0 block h-2 w-2 rounded-full ring-2 ring-[color:var(--app-header-background-color)] bg-[color:var(--app-header-new-notification-indicator-color)] group-hover:ring-[color:var(--app-header-mobile-menu-button-background-hover-color)]" />
                      )}
                    </span>
                  )}
                </Disclosure.Button>
                <RenderHooks id="app.header.menu.after" />
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            {({ close }) => (
              <Fragment>
                {/* <div className="pt-2 pb-3 space-y-1">

                </div> */}
                <div className="pt-4 pb-3 border-t border-[color:var(--dropdown-menu-entry-divider-color)]">
                  {isLoggedIn && (
                    <div className="flex items-center px-4">
                      <div className="flex-shrink-0">
                        <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-[color:var(--app-header-menu-button-background-color)]">
                          <svg
                            className="h-full w-full text-[color:var(--app-header-menu-button-text-color)]"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                          </svg>
                        </span>
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium text-[color:var(--text-color)]">
                          {identity?.name ?? 'Unnamed User'}
                        </div>
                        <div className="text-sm font-medium text-[color:var(--text-brightest-color)]">
                          {identity?.email ?? identity?.userId}
                        </div>
                      </div>

                      <Link
                        to="/task-list"
                        component={(props) => {
                          const { navigate, href } = props;

                          return (
                            <button
                              onClick={() => navigate(href)}
                              type="button"
                              className="ml-auto flex-shrink-0 bg-[color:var(--app-header-background-color)] p-1 rounded-full text-[color:var(--app-header-notification-icon-color)] hover:text-[color:var(--app-header-notification-icon-hover-color)] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[color:var(--focus-color)]"
                            >
                              <span className="sr-only">View notifications</span>
                              <span className="inline-block relative">
                                <BellIcon className="h-6 w-6" aria-hidden="true" />
                                {/* if new tasks available */}
                                {false && (
                                  <span className="absolute top-0 right-0 block h-2 w-2 rounded-full ring-2 ring-[color:var(--app-header-background-color)] bg-[color:var(--app-header-new-notification-indicator-color)]" />
                                )}
                              </span>
                            </button>
                          );
                        }}
                      ></Link>
                    </div>
                  )}
                  <div className="mt-3 space-y-1">
                    {isLoggedIn && (
                      <Fragment>
                        <Link
                          to="/startable-list"
                          onClick={() => close()}
                          className={classNames(
                            props.activeNav === 'startable-list'
                              ? 'bg-[color:var(--app-header-mobile-menu-active-background-color)] border-[color:var(--app-header-mobile-menu-active-border-color)] text-[color:var(--app-header-mobile-menu-active-text-color)] '
                              : 'border-transparent text-[color:var(--text-brightest-color)] hover:bg-[color:var(--app-header-mobile-menu-background-hover-color)] hover:border-[color:var(--app-header-mobile-menu-border-hover-color)] hover:text-[color:var(--text-bright-color)]',
                            'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                          )}
                        >
                          {t('Header.TitleStartableList')}
                        </Link>
                        <Link
                          to="/task-list"
                          onClick={() => close()}
                          className={classNames(
                            props.activeNav === 'task-list'
                              ? 'bg-[color:var(--app-header-mobile-menu-active-background-color)] border-[color:var(--app-header-mobile-menu-active-border-color)] text-[color:var(--app-header-mobile-menu-active-text-color)]'
                              : 'border-transparent text-[color:var(--text-brightest-color)] hover:bg-[color:var(--app-header-mobile-menu-background-hover-color)] hover:border-[color:var(--app-header-mobile-menu-border-hover-color)] hover:text-[color:var(--text-bright-color)]',
                            'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                          )}
                        >
                          {t('Header.TitleTaskList')}
                        </Link>
                        {/* divider */}
                        <div className="border border-[color:var(--dropdown-menu-entry-divider-color)]"></div>
                      </Fragment>
                    )}
                    <Link
                      to="/user-settings"
                      onClick={() => close()}
                      className={classNames(
                        props.activeNav === 'user-settings'
                          ? 'bg-[color:var(--app-header-mobile-menu-active-background-color)] border-[color:var(--app-header-mobile-menu-active-border-color)] text-[color:var(--app-header-mobile-menu-active-text-color)] '
                          : 'border-transparent text-[color:var(--text-brightest-color)] hover:bg-[color:var(--app-header-mobile-menu-background-hover-color)] hover:border-[color:var(--app-header-mobile-menu-border-hover-color)] hover:text-[color:var(--text-brightest-color)]',
                        'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                      )}
                    >
                      {settingsLabel}
                    </Link>

                    {isLoggedIn && (
                      <a
                        href="#"
                        onClick={() => {
                          close();
                          authService?.logout();
                        }}
                        className={classNames(
                          'border-transparent text-[color:var(--text-brightest-color)] hover:bg-[color:var(--app-header-mobile-menu-background-hover-color)] hover:border-[color:var(--app-header-mobile-menu-border-hover-color)] hover:text-[color:var(--text-brightest-color)]',
                          'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                        )}
                      >
                        {t('Header.Logout')}
                      </a>
                    )}
                  </div>
                </div>
              </Fragment>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
