import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { classNames, TaskListWithCorrelation } from '../../../lib/index';

type TasksByCorrelationGroupProps = TaskListWithCorrelation;

export function TasksByCorrelationGroup(props: TasksByCorrelationGroupProps): JSX.Element {
  const { t } = useTranslation();
  const { taskList: tasks, correlationDescription, correlation } = props;
  const correlationName = props.correlationName ?? t('TaskList.CorrelationNameUnknown');

  return (
    <Fragment>
      {tasks.map((task) => {
        return (
          <tr key={task.flowNodeInstanceId}>
            <td
              className={classNames(
                // prepared style switch for the future "new tasks indicator" feature
                true ? 'border-l-4 border-[color:var(--task-list-unseen-task-indicator-color)]' : '',
                'sm:whitespace-nowrap text-[color:var(--text-brightest-color)]'
              )}
              title={task.startedAt?.toISOString()}
            >{`${task.startedAt?.toLocaleDateString(undefined, {
              dateStyle: 'medium',
            })} ${task.startedAt?.toLocaleTimeString()}`}</td>

            <td className="font-medium text-[color:var(--text-color)] truncate max-w-[12rem] sm:max-w-[16rem] md:max-w-xs">
              <div className="flex items-center">
                <div className="truncate">
                  <span className="block text-[color:var(--text-color)] truncate" title={correlationName}>
                    {correlationName}
                  </span>
                  <span
                    className="block text-[color:var(--text-brightest-color)] truncate"
                    title={correlationDescription}
                  >
                    {correlationDescription}
                  </span>
                </div>
              </div>
            </td>

            <td className="text-[color:var(--text-brightest-color)]" title={task.flowNodeName}>
              {task.flowNodeName ?? t('TaskList.NoTaskName')}
            </td>
            <td className="text-right font-medium">
              <Link
                to={{
                  pathname: `task/${correlation.correlationId}/${task.processInstanceId}/${task.flowNodeInstanceId}`,
                  state: { task: task },
                }}
                className="text-[color:var(--link-color)] hover:text-[color:var(--link-hover-color)]"
              >
                {t('TaskList.Continue')}
                <span className="sr-only">, {task.flowNodeName ?? t('TaskList.NoTaskName')}</span>
              </Link>
            </td>
          </tr>
        );
      })}
    </Fragment>
  );
}
