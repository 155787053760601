import { createContext, useContext } from 'react';

import { EngineClient } from '@atlas-engine/atlas_engine_client';
import { BpmnType, DataModels, EventReceivedCallback, Messages, Subscription } from '@atlas-engine/atlas_engine_sdk';
import { PortalConfiguration, ProcessModelConfig } from '@atlas-engine-contrib/atlas-ui_contracts';

import { IAuthService } from './IAuthService';
import { AnyTaskType, EngineUnreachableError, isConnectionError } from './InternalTypes';

export const EngineContext = createContext<EngineService | undefined>(undefined);

export function withEngineService<TProps>(Component: React.ComponentType<TProps>): React.ComponentType<TProps> {
  const ComponentWithEngineService = (props: Omit<TProps, 'engineService'>) => {
    const engineService = useContext(EngineContext);

    // Unfortunately, the type assertion is necessary due to a likely bug in TypeScript
    // https://github.com/Microsoft/TypeScript/issues/28938
    return <Component {...(props as TProps)} engineService={engineService} />;
  };

  const componentName = Component.displayName ?? Component.name ?? 'Component';
  ComponentWithEngineService.displayName = `WithEngineService(${componentName})`;

  return ComponentWithEngineService;
}

export type OnCorrelationStateChangedCallback = (correlation: DataModels.Correlation.Correlation) => void;
export type OnCorrelationProgressCallback = (correlation: Messages.EventMessage) => void;

export class EngineService {
  private readonly authService: IAuthService;
  private readonly engineClient: EngineClient;

  private readonly config: PortalConfiguration;

  constructor(authService: IAuthService, config: PortalConfiguration) {
    this.authService = authService;
    this.config = config;
    this.engineClient = new EngineClient(config.engineUrl);
  }

  public async createAnonymousSessionIfNecessary(): Promise<void> {
    if (this.authService.getGrantType() !== 'client_credentials') {
      return;
    }

    try {
      const identity = await this.authService.getIdentity();
      if (identity.anonymousSessionId != null) {
        this.authService.setSessionId(identity.anonymousSessionId);
        return;
      }
      const sessionId = await this.engineClient.anonymousSession.createAnonymousSessionForIdentity(identity);
      this.authService.setSessionId(sessionId);
    } catch (error) {
      throw this.handleEngineRequestError(error);
    }
  }

  public getEngineBaseUrl(): string {
    return this.config.engineUrl;
  }

  public async getEngineVersion(): Promise<string> {
    try {
      const applicationInfo = await this.engineClient.applicationInfo.getApplicationInfo();
      return applicationInfo.version;
    } catch (error) {
      throw this.handleEngineRequestError(error);
    }
  }

  public async startProcessInstance(
    processModelId: string,
    payload: Record<string, unknown> | undefined = undefined,
    startEventId: string | undefined = undefined,
    correlationId: string | undefined = undefined
  ): Promise<DataModels.ProcessInstances.ProcessStartResponse> {
    try {
      const identity = await this.authService.getIdentity();
      const result = await this.engineClient.processDefinitions.startProcessInstance(
        {
          processModelId: processModelId,
          initialToken: payload,
          startEventId: startEventId,
          correlationId: correlationId,
        },
        identity
      );

      return result;
    } catch (error) {
      throw this.handleEngineRequestError(error);
    }
  }

  public async reserveUserTask(flowNodeInstanceId: string): Promise<void> {
    try {
      const currentIdentity = await this.authService.getIdentity();
      const userId = (
        this.authService.getGrantType() === 'client_credentials'
          ? currentIdentity.anonymousSessionId
          : currentIdentity.userId
      ) as string;

      await this.engineClient.userTasks.reserveUserTaskInstance(currentIdentity, flowNodeInstanceId, userId);
    } catch (error) {
      throw this.handleEngineRequestError(error);
    }
  }

  public async cancelUserTaskReservation(flowNodeInstanceId: string): Promise<void> {
    try {
      await this.engineClient.userTasks.cancelUserTaskInstanceReservation(
        await this.authService.getIdentity(),
        flowNodeInstanceId
      );
    } catch (error) {
      throw this.handleEngineRequestError(error);
    }
  }

  public async getCorrelation(correlationId: string): Promise<DataModels.Correlation.Correlation> {
    try {
      const correlation = await this.engineClient.correlations.getById(correlationId, {
        identity: await this.authService.getIdentity(),
      });

      const processInstancesByCorrelationId = await this.getProcessInstancesBy({ correlationId: correlationId });

      correlation.processInstances = processInstancesByCorrelationId;

      return correlation;
    } catch (error) {
      throw this.handleEngineRequestError(error);
    }
  }

  public async getProcessModels(): Promise<Array<ProcessModelConfig>> {
    try {
      const result = await this.engineClient.processDefinitions.getAll({
        identity: await this.authService.getIdentity(),
      });

      const configuredProcessModelList: Array<ProcessModelConfig> = [];

      for (const processDefinition of result.processDefinitions) {
        const configuredProcessModels = processDefinition.processModels
          .filter((processModel) => {
            const isInIncludeList =
              this.config.processModels.include?.length === 0 ||
              this.config.processModels.include?.some((id) => id === processModel.processModelId);

            const isNotInExcludeList = !this.config.processModels.exclude?.some(
              (id) => id === processModel.processModelId
            );

            return processModel.isExecutable && isInIncludeList && isNotInExcludeList;
          })
          .map((processModel) => this.configureProcessModel(processModel));

        configuredProcessModelList.push(...configuredProcessModels);
      }

      configuredProcessModelList.sort((processModelA, processModelB) => {
        const titleA = processModelA.title.toLowerCase();
        const titleB = processModelB.title.toLowerCase();

        return titleA.localeCompare(titleB);
      });

      return configuredProcessModelList;
    } catch (error) {
      throw this.handleEngineRequestError(error);
    }
  }

  public async onCorrelationStateChanged(
    correlationIdToSubscribeTo: string,
    callback: OnCorrelationStateChangedCallback
  ): Promise<Array<Subscription>> {
    try {
      const refreshCorrelationCallback = async (event: Messages.EventMessage): Promise<void> => {
        if (correlationIdToSubscribeTo === event.correlationId) {
          const correlation = await this.getCorrelation(correlationIdToSubscribeTo);
          callback(correlation);
        }
      };

      const identity = await this.authService.getIdentity();

      const newSubscriptions = await Promise.all([
        this.engineClient.notification.onProcessStarted(refreshCorrelationCallback.bind(this), {
          subscribeOnce: false,
          identity: identity,
        }),
        this.engineClient.notification.onProcessEnded(refreshCorrelationCallback.bind(this), {
          subscribeOnce: false,
          identity: identity,
        }),
        this.engineClient.notification.onProcessError(refreshCorrelationCallback.bind(this), {
          subscribeOnce: false,
          identity: identity,
        }),
        this.engineClient.notification.onProcessTerminated(refreshCorrelationCallback.bind(this), {
          subscribeOnce: false,
          identity: identity,
        }),
      ]);

      return newSubscriptions;
    } catch (error) {
      throw this.handleEngineRequestError(error);
    }
  }

  public async onProcessInstanceStateChanged(callback: EventReceivedCallback): Promise<Array<Subscription>> {
    try {
      const identity = await this.authService.getIdentity();

      const newSubscriptions = await Promise.all([
        this.engineClient.notification.onProcessEnded(callback, { subscribeOnce: false, identity: identity }),
        this.engineClient.notification.onProcessError(callback, { subscribeOnce: false, identity: identity }),
        this.engineClient.notification.onProcessStarted(callback, { subscribeOnce: false, identity: identity }),
        this.engineClient.notification.onProcessTerminated(callback, { subscribeOnce: false, identity: identity }),
      ]);

      return newSubscriptions;
    } catch (error) {
      throw this.handleEngineRequestError(error);
    }
  }

  public async onTaskStatesChanged(callback: EventReceivedCallback): Promise<Array<Subscription>> {
    try {
      const identity = await this.authService.getIdentity();

      const newSubscriptions = await Promise.all([
        this.engineClient.notification.onUserTaskFinished(callback, { subscribeOnce: false, identity: identity }),
        this.engineClient.notification.onUserTaskWaiting(callback, { subscribeOnce: false, identity: identity }),
        this.engineClient.notification.onUserTaskReserved(callback, { subscribeOnce: false, identity: identity }),
        this.engineClient.notification.onUserTaskReservationCanceled(callback, {
          subscribeOnce: false,
          identity: identity,
        }),
        this.engineClient.notification.onManualTaskFinished(callback, { subscribeOnce: false, identity: identity }),
        this.engineClient.notification.onManualTaskWaiting(callback, { subscribeOnce: false, identity: identity }),
      ]);

      return newSubscriptions;
    } catch (error) {
      throw this.handleEngineRequestError(error);
    }
  }

  public async onTaskWaiting(
    callback: Messages.CallbackTypes.OnUserTaskWaitingCallback | Messages.CallbackTypes.OnManualTaskWaitingCallback
  ): Promise<Array<Subscription>> {
    try {
      const identity = await this.authService.getIdentity();

      const newSubscriptions = await Promise.all([
        this.engineClient.notification.onUserTaskWaiting(callback, { subscribeOnce: false, identity: identity }),
        this.engineClient.notification.onManualTaskWaiting(callback, { subscribeOnce: false, identity: identity }),
      ]);

      return newSubscriptions;
    } catch (error) {
      throw this.handleEngineRequestError(error);
    }
  }

  public async onDeployedProcessesChanged(
    callback: Messages.CallbackTypes.OnProcessDeployedCallback
  ): Promise<Array<Subscription>> {
    try {
      const identity = await this.authService.getIdentity();

      const newSubscriptions = await Promise.all([
        this.engineClient.notification.onProcessDeployed(callback, { subscribeOnce: false, identity: identity }),
        this.engineClient.notification.onProcessUndeployed(callback, { subscribeOnce: false, identity: identity }),
      ]);

      return newSubscriptions;
    } catch (error) {
      throw this.handleEngineRequestError(error);
    }
  }

  public async onCorrelationProgress(
    correlationId: string,
    callback: OnCorrelationProgressCallback
  ): Promise<Array<Subscription>> {
    try {
      const identity = await this.authService.getIdentity();

      const correlationCheckCallback = (message: Messages.EventMessage): void => {
        if (message.correlationId === correlationId) {
          callback(message);
        }
      };

      const newSubscriptions = await Promise.all([
        this.engineClient.notification.onActivityReached(correlationCheckCallback, {
          subscribeOnce: false,
          identity: identity,
        }),
        this.engineClient.notification.onActivityFinished(correlationCheckCallback, {
          subscribeOnce: false,
          identity: identity,
        }),
        this.engineClient.notification.onIntermediateCatchEventReached(correlationCheckCallback, {
          subscribeOnce: false,
          identity: identity,
        }),
        this.engineClient.notification.onIntermediateCatchEventFinished(correlationCheckCallback, {
          subscribeOnce: false,
          identity: identity,
        }),
        this.engineClient.notification.onIntermediateThrowEventTriggered(correlationCheckCallback, {
          subscribeOnce: false,
          identity: identity,
        }),
        this.engineClient.notification.onUserTaskReserved(correlationCheckCallback, {
          subscribeOnce: false,
          identity: identity,
        }),
        this.engineClient.notification.onUserTaskReservationCanceled(correlationCheckCallback, {
          subscribeOnce: false,
          identity: identity,
        }),
        this.engineClient.notification.onCorrelationMetadataChanged(correlationCheckCallback, {
          subscribeOnce: false,
          identity: identity,
        }),
        this.engineClient.notification.onProcessInstanceMetadataChanged(correlationCheckCallback, {
          subscribeOnce: false,
          identity: identity,
        }),
      ]);

      return newSubscriptions;
    } catch (error) {
      throw this.handleEngineRequestError(error);
    }
  }

  public async onNewTaskWaiting(
    correlationId: string,
    callback: (message: Messages.EventMessage) => void
  ): Promise<Subscription[]> {
    try {
      const identity = await this.authService.getIdentity();

      const correlationCheckCallback = (message: Messages.EventMessage): void => {
        if (message.correlationId === correlationId) {
          callback(message);
        }
      };

      const subscriptions = await Promise.all([
        this.engineClient.notification.onUserTaskWaiting(correlationCheckCallback, {
          subscribeOnce: false,
          identity: identity,
        }),
        this.engineClient.notification.onManualTaskWaiting(correlationCheckCallback, {
          subscribeOnce: false,
          identity: identity,
        }),
      ]);

      return subscriptions;
    } catch (error) {
      throw this.handleEngineRequestError(error);
    }
  }

  public async removeSubscriptions(subscriptions: Array<Subscription>): Promise<void> {
    const identity = await this.authService.getIdentity();
    subscriptions.forEach((subscription) => this.engineClient.notification.removeSubscription(subscription, identity));
  }

  public async getTasks(
    sortSettings?: DataModels.FlowNodeInstances.FlowNodeInstanceSortSettings
  ): Promise<Array<AnyTaskType>> {
    return this.queryUserTasksAndManualTasks(
      {
        state: DataModels.FlowNodeInstances.FlowNodeInstanceState.suspended,
      },
      { sortSettings: sortSettings }
    );
  }

  public async getTasksInCorrelation(correlationId: string): Promise<Array<AnyTaskType>> {
    return this.queryUserTasksAndManualTasks({
      correlationId: correlationId,
      state: DataModels.FlowNodeInstances.FlowNodeInstanceState.suspended,
    });
  }

  public async getTaskByFlowNodeInstanceId(flowNodeInstanceId: string): Promise<AnyTaskType | undefined> {
    const tasks = await this.queryUserTasksAndManualTasks({
      flowNodeInstanceId: flowNodeInstanceId,
      state: DataModels.FlowNodeInstances.FlowNodeInstanceState.suspended,
    });

    return tasks.length > 0 ? tasks.pop() : undefined;
  }

  public async getProcessInstance(
    correlationId: string,
    processInstanceId: string
  ): Promise<DataModels.ProcessInstances.ProcessInstance | undefined> {
    try {
      const result = await this.engineClient.processInstances.query(
        {
          correlationId: correlationId,
          processInstanceId: processInstanceId,
        },
        { identity: await this.authService.getIdentity(), includeXml: false }
      );

      return result.processInstances.length > 0 ? result.processInstances.pop() : undefined;
    } catch (error) {
      throw this.handleEngineRequestError(error);
    }
  }

  public async getProcessInstancesBy(
    query: DataModels.ProcessInstances.ProcessInstanceQuery,
    options: Parameters<typeof this.engineClient.processInstances.query>[1] = {}
  ): Promise<Array<DataModels.ProcessInstances.ProcessInstance>> {
    try {
      const result = await this.engineClient.processInstances.query(query, {
        identity: await this.authService.getIdentity(),
        ...options,
        includeXml: false,
      });
      return result.processInstances;
    } catch (error) {
      throw this.handleEngineRequestError(error);
    }
  }

  public async terminateProcessInstance(processInstanceId: string): Promise<void> {
    return this.engineClient.processInstances.terminateProcessInstance(
      processInstanceId,
      await this.authService.getIdentity()
    );
  }

  public onProcessInstanceMetadataChanged(
    processInstanceId: string,
    callback: Parameters<typeof this.engineClient.notification.onProcessInstanceMetadataChanged>[0]
  ): ReturnType<typeof this.engineClient.notification.onProcessInstanceMetadataChanged> {
    return this.engineClient.notification.onProcessInstanceMetadataChanged(callback);
  }

  private configureProcessModel(processModelFromApi: DataModels.ProcessDefinitions.ProcessModel): ProcessModelConfig {
    const processModelSettings = this.getProcessModelSettingsFromConfig(processModelFromApi.processModelId);

    const processModelConfig: ProcessModelConfig = {
      id: processModelFromApi.processModelId,
      title: processModelSettings?.title ?? processModelFromApi.processModelName ?? processModelFromApi.processModelId,
      body: processModelSettings?.body ?? '',
      startButtonTitles: {},
    };

    processModelFromApi.startEvents.forEach((startEvent) => {
      const startEventTitlefromConfig =
        processModelSettings?.startButtonTitles && Object.keys(processModelSettings.startButtonTitles).length > 0
          ? processModelSettings?.startButtonTitles[startEvent.id]
          : undefined;

      processModelConfig.startButtonTitles[startEvent.id] =
        startEventTitlefromConfig ?? startEvent.name ?? startEvent.id;
    });

    if (processModelSettings?.groupId) {
      processModelConfig.groupId = processModelSettings.groupId;
    }

    return processModelConfig;
  }

  private getProcessModelSettingsFromConfig(processModelId: string): ProcessModelConfig | undefined {
    if (!this.config.processModels.settings) {
      return undefined;
    }

    return this.config.processModels.settings[processModelId];
  }

  private async queryUserTasksAndManualTasks(
    query: DataModels.FlowNodeInstances.GenericFlowNodeInstanceQuery,
    options: Parameters<typeof this.engineClient.flowNodeInstances.query>[1] = {}
  ): Promise<Array<AnyTaskType>> {
    try {
      const results = await this.engineClient.flowNodeInstances.query(
        {
          ...query,
          flowNodeType: [BpmnType.manualTask, BpmnType.userTask],
        },
        {
          identity: await this.authService.getIdentity(),
          ...options,
        }
      );

      return results.flowNodeInstances;
    } catch (error) {
      throw this.handleEngineRequestError(error);
    }
  }

  private handleEngineRequestError(error: any): void {
    if (isConnectionError(error)) {
      throw new EngineUnreachableError(error.code);
    }

    throw error;
  }
}
